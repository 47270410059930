@import "~@angular/material/theming";

/* breakpoints */
$breakpoint-mobile-max: 1050px;
$breakpoint-desktop-min: 1050.1px;

/* colors */
$light: white;
$dark: black;
$hawkeye-gold: #ffcd00;

/* CSI colors */
$csi-red: #b70000;
$csi-green: #00bb00;
$csi-dark-green: #3d9400;
$csi-blue: #428bca;
$csi-light-blue: #75baff;
$csi-dark-gray: #7f7f7f;
$csi-medium-gray: #8f8f8f;
$csi-light-gray: #d8d8d8;
$csi-lighter-gray: #f0f0f0;
$csi-light-gold: #fff7d2;

/* UI Sanctioned Palette */
$dark-orange: #e35205;
$dark-green: #00664f;
$dark-blue: #0055bc;
$dark-cool-gray: #63666a;
$light-orange: #ff8200;
$light-green: #00c389;
$light-blue: #00a9e0;
$light-cool-gray: #bbbcbc;

/* other colors */

/* end colors */

/* warnings */
$warning-red: $csi-red;
$warning-orange: $dark-orange;

/* buttons */
$button-green: $light-green;
$button-blue: $light-blue;

/* tables */
$table-striping: $light-cool-gray;
$table-hover: $csi-light-gold;

/* fonts */
$default-font-size: 14px;
$default-font-family: Roboto, "Helvetica Neue", sans-serif;

/* other */
$border-radius: 5px;
$default-space: 10px;

/* preemie colors */
$preemie-blue: #00558c;
$preemie-yellow: #fff5a8;
$preemie-gray: #eee;
