@use 'src/assets/css/_variables.scss';

@font-face {
  font-family: "Antonio";
  src: local("Antonio"), url(./assets/fonts/antonio/Antonio-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Zilla Slab";
  src: local("Zilla Slab"), url(./assets/fonts/zilla/ZillaSlab-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./assets/fonts/roboto/Roboto-Regular.ttf) format("truetype");
}

*,
html,
body {
  box-sizing: border-box;

}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: variables.$default-font-family;
  font-size: clamp(16px, 1.5vw, 1rem);
  line-height: clamp(16px, 1.5vw, 1rem);
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.125;
  letter-spacing: 0; }

h1, .h1 {
  font-size: 3.35rem;
  line-height: unset;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  font-family: "Zilla Slab", serif;
  font-weight: 600;
  font-size: clamp(2.3rem, calc(2.3662vw + 1.4127rem), 3.35rem); }

.h1.bold-headline--serif, .h1.headline--serif {
  font-size: 3.35rem;
  line-height: unset;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  font-family: "Zilla Slab", serif;
  font-weight: 600;
  font-size: clamp(2.3rem, calc(2.3662vw + 1.4127rem), 3.35rem);
  font-weight: 700; }

h2, .h2 {
  font-size: 2.3rem;
  line-height: 1.25;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.325rem;
  font-size: clamp(1.95rem, calc(0.7887vw + 1.6542rem), 2.3rem) !important; }
h2:not(:first-child), .h2:not(:first-child) {
  margin-top: 1.875rem; }
h2.bold-headline--serif, h2.headline--serif, .h2.bold-headline--serif, .h2.headline--serif {
  font-size: clamp(1.75rem, calc(1.7465vw + 1.0951rem), 2.525rem); }

h3, .h3 {
  font-size: 1.9125rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.325rem;
  font-size: clamp(1.65rem, calc(0.5915vw + 1.4282rem), 1.9125rem) !important; }
h3:not(:first-child), .h3:not(:first-child) {
  margin-top: 1.875rem; }
h3.bold-headline--serif, h3.headline--serif, .h3.bold-headline--serif, .h3.headline--serif {
  font-size: clamp(1.65rem, calc(1.0986vw + 1.238rem), 2.1375rem); }

h4, .h4 {
  font-size: 1.6rem;
  line-height: 1.25;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.325rem;
  font-size: clamp(1.45rem, calc(0.338vw + 1.3232rem), 1.6rem); }
h4:not(:first-child), .h4:not(:first-child) {
  margin-top: 1.875rem; }
h4.bold-headline--serif, h4.headline--serif, .h4.bold-headline--serif, .h4.headline--serif {
  font-size: clamp(1.45rem, calc(0.8451vw + 1.1331rem), 1.825rem); }

h5, .h5 {
  font-size: 1.4rem;
  line-height: 1.25;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.325rem;
  font-size: clamp(1.3rem, calc(0.2254vw + 1.2155rem), 1.4rem); }
h5:not(:first-child), .h5:not(:first-child) {
  margin-top: 1.875rem; }
h5.bold-headline--serif, h5.headline--serif, .h5.bold-headline--serif, .h5.headline--serif {
  font-size: clamp(1.3rem, calc(0.7324vw + 1.0254rem), 1.625rem); }

h6, .h6 {
  font-size: 1.2rem;
  line-height: 1.25;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.325rem; }
h6:not(:first-child), .h6:not(:first-child) {
  margin-top: 1.875rem; }
h6.bold-headline--serif, h6.headline--serif, .h6.bold-headline--serif, .h6.headline--serif {
  font-size: clamp(1.2rem, calc(0.507vw + 1.0099rem), 1.425rem); }

p,
dl,
ol,
ul,
blockquote,
pre,
table {
  font-weight: 400; }

p {
  max-width: 1400px;
  line-height: 1.6;
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 1.05rem; }

a {
  color: #00558C;
}

a:hover {
  text-decoration: underline !important; }

.bttn {
  font-family: "Antonio", Arial, Helvetica, sans-serif;
  border: none;
  display: inline-block;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.4 !important;
  font-size: 1.25rem;
  padding: 1rem 2rem 1rem !important;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }
.bttn i,
.bttn svg {
  margin-left: 0.625rem; }
.bttn--primary {
  color: #151515;
  background: #FFCD00;
  border: 1px solid #FFCD00; }
.bttn--primary i,
.bttn--primary svg,
.bttn--primary span {
  color: #fff; }
.bttn--primary:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: #FFCD00;
  transform-origin: center;
  transform: translate(-50%, 0) scaleX(0);
  transition: transform 0.3s ease-in-out; }
.bttn--primary:focus {
  text-decoration: underline; }
.bttn--primary:hover::after, .bttn--primary:focus::after {
  transform: translate(-50%, 0) scaleX(1); }
.bttn--primary:after {
  background-color: #151515; }
[class*="bg--"] [class*="bg--black"] .bttn--primary,
[class*="bg--black"] .bttn--primary {
  color: #151515; }
[class*="bg--"] [class*="bg--gray"] .bttn--primary,
[class*="bg--"] [class*="bg--white"] .bttn--primary,
[class*="bg--gray"] .bttn--primary,
[class*="bg--white"] .bttn--primary {
  border-color: #FFCD00; }

section {
  flex-wrap: wrap;
}

h1,
h2,
h3,
h4 {
  font-family: "Roboto Medium", helvetica, arial, sans-serif;
}

h2 {
  font-size: 3.375rem !important;
  line-height: 1.25em !important;
  letter-spacing: -0.02em !important;
  color: #000;
}

h3 {
  font-size: 35px !important;
  margin: 1.2em 0 0.5em 0 !important;
  padding-bottom: .5em;
}

a:hover, a:focus {
  text-decoration: underline;
}

h3.page-heading {
  border-bottom: 10px variables.$hawkeye-gold double;
  font-size: 35px;
  font-weight: 600;
  margin: auto !important;
  padding-bottom: 16px;
  width: 300px;
}

h4 {
  font-size: 1.8rem !important;
  line-height: 1.25em !important;
}

p {
  margin-bottom: 0 !important;
}

aside mat-nav-list a:hover {
  background-color: variables.$preemie-blue !important;
  color: white !important;
}

aside mat-nav-list a:nth-child(odd) {
  background-color: variables.$preemie-gray;
}

p,
ul,
ol,
dd,
dl {
  font-weight: normal;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1.5em;
}

aside h2 {
  font-size: 1.5em;
  font-weight: 600;
  padding-bottom: 0.25em;
}

aside button {
  width: 100%;
}

aside img {
  max-width: 200px;
  height: 200px;
}

aside.resources {
  width: 20%;
  min-width: 300px;
}

section {
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.video-wrapper  {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

video {
  width: 100%    !important;
  max-width: 1024px !important;
  max-height: 576px !important;
  -webkit-transform: translate3d(0,0,0);
}

video::-webkit-media-controls-panel {
  background-image: linear-gradient(transparent, variables.$dark-cool-gray) !important;
}

/* Angular Material Components */
.mat-drawer-content {
  overflow-x: hidden !important;
}

.mat-drawer-content {
  display: initial !important;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font: 400 18px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-toolbar h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
}

.mat-chip-list-wrapper input.mat-input-element, .mat-chip-list-wrapper .mat-standard-chip {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.sidenav-container {
  height: auto !important;
  background-color: white !important;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font: 500 35px Roboto, "Helvetica Neue", sans-serif;
  font-size: 3.375rem;
  margin-bottom: 30px;
  letter-spacing: -0.02em;
  color: #000;
}

.mat-expansion-panel {
  background-color: white;
  margin: 10px !important;
  box-shadow: none !important;
}

.mat-expansion-panel-header,
.mat-expansion-panel-header:hover {
  background-color: variables.$hawkeye-gold !important;
  font-weight: 600;
  font-size: 100%;
  border-radius: 2.5px !important;
}

.mat-expansion-panel.mat-expanded {
  border: 1px solid variables.$hawkeye-gold;
}

.mat-grid-tile .mat-figure {
  margin: 5px !important;
  height: auto !important;
}

.mat-standard-chip {
  font-size: 18px;
  padding: 24px !important;
  border-radius: 100px !important;
  background-color: variables.$hawkeye-gold !important;
}

.material-icons {
  margin-right: 4px;
  margin-left: 4px;
}

mat-grid-tile {
  background-color: #fcf8f2;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  height: 40px !important;
  border-left: 4px solid variables.$hawkeye-gold;
  border-top: 4px solid variables.$hawkeye-gold;
  border-bottom: 4px solid variables.$hawkeye-gold;
}

mat-grid-tile:last-child {
  border-right: 4px solid variables.$hawkeye-gold;
}

/* Custom Classes */
.offscreen {
  position: absolute;
  overflow: hidden;
  left: -999px;
}

.banner,
.bottom-banner {
  padding: 100px 0;
}

.banner {
  font-style: italic;
}

.content {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin-left: 5%;
  margin-right: 5%;
}

.badge  {
  margin-top: 16px;
  margin-right: 10px;
}

.yellow-badge {
  padding: 1.5em;
  border-left: 4px solid variables.$hawkeye-gold;
  overflow: hidden;
  margin-top: 60px;
}

.yellow-underline::after {
  content: "";
  display: block;
  border-bottom: 5px solid variables.$hawkeye-gold;
  width: 50px;
  position: relative;
  bottom: -6px;
}

.yellow-background {
  background-color: #fcf8f2;
  border: 4px solid variables.$hawkeye-gold;
  overflow: hidden;
}

.resources h3 {
  padding-bottom: 0;
}

.card-container {
  --flow-space: 2.5rem;
}

.card-list {
  --flow-space: 1.5rem;
  display: grid;
  flex-wrap: wrap;
  grid-template-rows: repeat(3, minmax(75px, auto));
  grid-gap: 1rem 5rem;
}

.wide-card-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.card-item {
  display: grid;
  align-items: stretch;
  padding: 10px;
  max-width: 500px !important;
  min-width: 320px !important;
}

.white-button {
  background-color: white;
  color: black;
  border: solid 1px black !important;
  border-radius: 0 !important;
}

.white-button:hover {
  background-color: black;
  color: white;
  border: solid 1px black !important;
  border-radius: 0 !important;
}

.black-button {
  background-color: black;
  color: white;
  border: solid 1px black !important;
  border-radius: 0 !important;
  height: 60px;
  font-size: 100%;
  padding: 20px;
  min-width: 140px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.black-button:hover {
  background-color: white;
  color: black;
  text-decoration: none;
}

.black-button .material-icons {
  font-size: 28px;
  margin-bottom: 2.5px;
}

.begin-button {
  background-color: variables.$hawkeye-gold !important;
  border-color: variables.$hawkeye-gold !important;
  color: #000 !important;
  font-size: 1.5238em;
  padding: .5em .8em;
  line-height: 1.5em;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0 !important;
  text-decoration: none;
}

.next {
  text-align: end !important;
}

.prev {
  text-align: start !important;
}

.nav-button {
  width: 100%;
}

.column-spacer {
  flex-grow: 4;
}

#progress-outer {
  width: 100%;
  height: 100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}

#progress-inner {
  width: 100%;
}

.show-spinner {
  background-color: white;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}
.hide-spinner {
  display: none;
  z-index: -1;
  top: -10000px;
  left: -10000px;
}

/* Global Classes */
.margin {
  margin: 60px 1.5em !important;
}

.margin-top {
  margin-top: 60px !important;
}

.margin-left {
  margin-left: 1.5em !important;
}

.margin-right {
  margin-right: 1.5em !important;
}

.margin-bottom {
  margin-bottom: 60px !important;
}

.flow > * {
  margin-top: var(--flow-space, 2em);
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.full-width {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}

.text-center {
  text-align: center !important;
}

.border-bottom {
  border-bottom: 1px solid #d5d5d5;
}

.border-top {
  border-top: 1px solid #d5d5d5;
}

.yellow-badge {
  padding: 1.5em;
  border-left: 4px solid variables.$hawkeye-gold;
  overflow: hidden;
  margin-top: 60px;
}

.yellow-underline::after {
  content: "";
  display: block;
  border-bottom: 5px solid variables.$hawkeye-gold;
  width: 50px;
  position: relative;
  bottom: -6px;
}

.italic-paragraph {
  font-style: italic;
  font-size: 1rem;
}

@media (min-width: 1400px) {
  section {
    margin-left: 5%;
    margin-right: 5%;
  }
}

/* Mobile Styles */
@media (max-width: 1000px) {
  // Native HTML Elements
  section {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    flex-wrap: wrap;
    padding: 0;
  }

  figure  {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }

  h4 {
    font-size: 24px !important;
  }

  iframe  {
    border: 4px solid variables.$hawkeye-gold;
    border-radius: 20px;
    height: 100% !important;
    width: 100% !important;
    margin-left: 0 !important;
  }

  // Angular Material Components
  mat-grid-tile  {
    font-size: 10px !important;
  }

  // Custom Classes
  .content {
    width: 95%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .yellow-badge {
    padding: 0;
    border-left: none;
    overflow: hidden;
    margin-top: 0;
  }

  .black-button {
    max-width: 50%;
  }

  .resources  {
    display: none;
  }

  .wide-card-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  // Global Classes
  .margin {
    margin: 40px 2.5% !important;
  }

  .margin-left {
    margin-left: 2.5% !important;
  }

  .margin-right {
    margin-right: 2.5% !important;
  }

  .margin-top {
    margin-top: 40px !important;
  }

  .margin-bottom {
    margin-top: 40px !important;
  }
}

/* Tablet Styles */
@media (max-width: 900px) {
  video {
    max-width: 800px !important;
    max-height: 450px !important;
  }
}

/* Phone Styles */
@media (max-width: 500px) {
  video {
    max-width: 400px !important;
    max-height: 225px !important;
  }
  .video-wrapper  {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
